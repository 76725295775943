import React, { useState } from "react";
import Header from "./components/Header";
import BodyContainer from "./components/BodyContainer.tsx";
import Livestream from "./components/Livestream";
import Admin from "./components/AdminSurface/Admin";
import About from "./components/About";
import Login from "./components/auth/Login";
import Archives from "./components/Archives";
//import Archives from "./components/Archives";
import "./Assets/css/default.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SoonspinsDataProvider from "./components/SoonspinsDataProvider";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { DataMode } from "./hooks/useSoonspinsData";
function App() {
  const [artists, setArtists] = useState({});

  const history = useHistory();
  const onAuthRequired = () => {
    history.push("/login");
  };

  const oktaAuth = new OktaAuth({
    issuer: "https://dev-611338.okta.com/oauth2/default",
    clientId: "0oaf4xvvneWRYugLF4x6",
    redirectUri: window.location.origin + "/implicit/callback",
  });
  console.log("here");

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} pkce={true}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Header style={{ zIndex: 5 }} />
            <SoonspinsDataProvider mode={DataMode.FRONT_PAGE}>
              <BodyContainer />
            </SoonspinsDataProvider>
          </Route>
          <Route path="/archives">
            <SoonspinsDataProvider mode={DataMode.ARCHIVED}>
              <Archives />
            </SoonspinsDataProvider>
          </Route>
          <Route exact path="/chat" component={Livestream} />
          <Route exact path="/about" component={About} />
          <SecureRoute exact path="/admin" component={Admin} />
          <Route
            exact
            path="/login"
            render={() => (
              <Login issuer="https://dev-611338.okta.com/oauth2/default" />
            )}
          />
          <Route exact path="/implicit/callback" component={LoginCallback} />
        </Switch>
      </Router>
    </Security>
  );
}

export default App;
