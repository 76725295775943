export type SoonspinsShow = {
    id: number;
    title: string;
    description: string;
    image_url: string;
    embed_code: string;
    submission_date: Date;
};

export type SoonspinsShows = {
    [id: number]: SoonspinsShow;
};

export type DatedSoonspinsShows = {
    [date: string]: SoonspinsShow[];
};

export const NUM_TO_MONTH: Record<number, string> = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
};