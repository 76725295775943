import { useEffect, useState } from "react";
import { SoonspinsShows, SoonspinsShow, DatedSoonspinsShows } from "../util";

export enum DataMode {
  FRONT_PAGE = "FRONT_PAGE",
  ARCHIVED = "ARCHIVED",
}

export const useSoonspinsData = (mode: DataMode) => {
  const [shows, setShows] = useState<SoonspinsShow[]>([]);
  const [archivedShows, setArchivedShows] = useState({});
  const [archivedShowsLoading, setArchivedShowsLoading] = useState(true);
  const [showsLoading, setShowsLoading] = useState<boolean>(false);
  const api_root = process.env.REACT_APP_API_ROOT
    ? process.env.REACT_APP_API_ROOT
    : window.location.origin;
  useEffect(() => {
    let ignore = false;

    if (mode === DataMode.FRONT_PAGE) {
      setShowsLoading(true);
      fetch("https://hello-n4jjjbspiq-uc.a.run.app/shows", {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        method: "get",
      })
        .then((response) => response.json())
        .then((data) => {
          if (!ignore) {
            setShows(data);
            setShowsLoading(false);
          }
        })
        .catch((error) => {
          setShowsLoading(false);
          console.log(error);
        });
    } else if (mode === DataMode.ARCHIVED) {
      setArchivedShowsLoading(true);
      fetch("https://hello-n4jjjbspiq-uc.a.run.app/shows/archives", {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!ignore) {
            console.log(data);
            setArchivedShows(data);
            setArchivedShowsLoading(false);
          }
        })
        .catch((error) => {
          setArchivedShowsLoading(false);
          console.log(error);
        });
    }

    return () => {
      ignore = true;
    };
  }, []);
  return { shows, showsLoading, archivedShows, archivedShowsLoading };
};
